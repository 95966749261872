/*!*************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js!./node_modules/sass-loader/dist/cjs.js!./src/scss/cookieconsent_v2.scss ***!
  \*************************************************************************************************************************/
#cookie-consent-notification {
  position: fixed;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(18, 18, 18, 0.45);
  z-index: 999999;
  font-size: 62.5%; }
  #cookie-consent-notification #cookie-consent-notification-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 84em;
    max-height: calc(100vh - 2em);
    width: calc(100% - 2em);
    padding: 2em 2em 9em;
    margin: 1em;
    overflow-y: auto;
    background: #FFFFFF;
    color: #333333;
    box-shadow: 0.1em 0.5em 1.5em 0 rgba(0, 0, 0, 0.3); }
    @media (min-width: 768px) {
      #cookie-consent-notification #cookie-consent-notification-wrapper {
        left: 50%;
        top: 50%;
        width: 100%;
        margin: 0;
        padding: 3em 4em;
        transform: translate(-50%, -50%); } }
    #cookie-consent-notification #cookie-consent-notification-wrapper p {
      line-height: 1.45;
      font-size: 1.4em; }
      @media (min-width: 768px) {
        #cookie-consent-notification #cookie-consent-notification-wrapper p {
          font-size: 1.6em; } }
    #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions {
      display: none; }
      #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input + label {
        margin-top: 0 !important;
        font-size: 1.2em;
        line-height: 1.25;
        color: #A6A6A6; }
        #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input + label strong {
          display: block;
          font-size: 1.4em;
          line-height: 1.45;
          color: #333333; }
      #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-left: 0; }
        #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions ul > li {
          display: block;
          width: 100%; }
          @media (min-width: 768px) {
            #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions ul > li {
              width: 48%; } }
      #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input {
        user-select: none; }
        #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="radio"] {
          display: none; }
          #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="radio"] + label {
            display: inline-block;
            margin-right: 1.5em;
            margin-bottom: .8em;
            color: #333333;
            cursor: pointer;
            user-select: none; }
            #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="radio"] + label span {
              display: inline-block;
              width: 2.4em;
              height: 2.4em;
              margin: -.4em .7em 0 0;
              vertical-align: middle;
              border-radius: 50%;
              background-color: #FAFAFA;
              border: 0.1em solid #D9D9D9;
              transition: all .3s linear;
              cursor: pointer; }
          #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="radio"]:checked + label span {
            background-color: #136682;
            border-color: #136682; }
        #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="checkbox"] {
          position: absolute;
          left: -999em; }
          #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="checkbox"] + label {
            position: relative;
            display: inline-block;
            width: 100%;
            padding-left: 3em;
            margin-bottom: 1.5em;
            cursor: pointer;
            user-select: none; }
            #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="checkbox"] + label::before {
              position: absolute;
              left: 0;
              top: .2em;
              width: 2em;
              height: 2em;
              background: #FAFAFA;
              border: 0.1em solid #D9D9D9;
              border-radius: .3em;
              content: ''; }
            #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="checkbox"] + label::after {
              position: absolute;
              left: .7em;
              top: .45em;
              width: .6em;
              height: 1.2em;
              border: solid #136682;
              border-width: 0 .3em .3em 0;
              transform: rotate(45deg) scale(0);
              transition: all .2s;
              opacity: 0;
              content: ''; }
            #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="checkbox"] + label span {
              position: relative;
              top: .6em; }
          #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="checkbox"]:checked + label::after {
            opacity: 1;
            transform: rotate(45deg) scale(1); }
          #cookie-consent-notification #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input[type="checkbox"]:disabled + label::before {
            box-shadow: none;
            border-color: #A6A6A6;
            background-color: #D9D9D9; }
    #cookie-consent-notification #cookie-consent-notification-wrapper .btn {
      display: inline-block;
      height: 4.8rem;
      line-height: 4.8rem;
      margin: 0;
      padding: 0 2rem;
      border-radius: .3em;
      cursor: pointer;
      font-family: Arial, sans-serif;
      font-size: 1.6em;
      font-weight: 400;
      white-space: nowrap;
      text-decoration: none;
      outline: none;
      color: #FFFFFF;
      background-color: #e5e5e5;
      border-color: #FFFFFF; }
      #cookie-consent-notification #cookie-consent-notification-wrapper .btn:hover, #cookie-consent-notification #cookie-consent-notification-wrapper .btn:focus, #cookie-consent-notification #cookie-consent-notification-wrapper .btn:active {
        background-color: #cccccc; }
      #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-lg {
        line-height: 4.8rem; }
      #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-sm {
        padding: 0 1rem;
        height: 3.4rem;
        line-height: 3.4rem; }
      #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary {
        color: #FFFFFF;
        background-color: #136682;
        border-color: #136682; }
        #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary svg {
          fill: #FFFFFF; }
        #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary:hover, #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary:focus, #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary:active {
          color: #FFFFFF;
          background-color: #10556c;
          border-color: #10556c; }
          #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary:hover svg, #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary:focus svg, #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-primary:active svg {
            fill: #FFFFFF; }
      @media (max-width: 479px) {
        #cookie-consent-notification #cookie-consent-notification-wrapper .btn.-full-mobile {
          display: block;
          width: 100%;
          text-align: center; } }
    #cookie-consent-notification #cookie-consent-notification-wrapper .cookie-consent-notification-headline {
      font-size: 2.8em;
      font-weight: 700;
      padding-bottom: .5em; }
    #cookie-consent-notification #cookie-consent-notification-wrapper .cookie-consent-notification-content {
      padding-bottom: 1rem; }
      #cookie-consent-notification #cookie-consent-notification-wrapper .cookie-consent-notification-content p {
        margin-bottom: 0;
        padding-bottom: 0; }
    #cookie-consent-notification #cookie-consent-notification-wrapper .cookie-consent-notification-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 1.5em 0 0 0; }
      #cookie-consent-notification #cookie-consent-notification-wrapper .cookie-consent-notification-buttons > li {
        display: block;
        width: 100%; }
        @media (min-width: 480px) {
          #cookie-consent-notification #cookie-consent-notification-wrapper .cookie-consent-notification-buttons > li {
            width: 48%; } }
        #cookie-consent-notification #cookie-consent-notification-wrapper .cookie-consent-notification-buttons > li:last-of-type {
          text-align: right; }
  #cookie-consent-notification.position-bottom, #cookie-consent-notification.position-top {
    top: inherit;
    bottom: 0;
    height: auto; }
    #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper {
      max-width: inherit; }
      @media (min-width: 768px) {
        #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper {
          left: 0;
          top: 100%;
          width: 100%;
          padding: 1.5em 3em 2em 3em;
          transform: translate(0, -100%); } }
      #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper .cookie-consent-notification-headline, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper .cookie-consent-notification-headline {
        font-size: 2.4em;
        padding-bottom: .25em; }
      #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper .cookie-consent-notification-content, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper .cookie-consent-notification-content {
        padding-bottom: 1rem; }
        @media (min-width: 768px) {
          #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper .cookie-consent-notification-content p, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper .cookie-consent-notification-content p {
            font-size: 1.2em; } }
      #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper #cookie-consent-notification-permissions, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper #cookie-consent-notification-permissions {
        display: none;
        width: 100%; }
        #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input + label, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper #cookie-consent-notification-permissions input + label {
          font-size: 1.2em; }
        @media (min-width: 768px) {
          #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper #cookie-consent-notification-permissions ul > li, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper #cookie-consent-notification-permissions ul > li {
            width: 48%; } }
        @media (min-width: 1024px) {
          #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper #cookie-consent-notification-permissions ul > li, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper #cookie-consent-notification-permissions ul > li {
            width: 32%; } }
      #cookie-consent-notification.position-bottom #cookie-consent-notification-wrapper .cookie-consent-notification-buttons, #cookie-consent-notification.position-top #cookie-consent-notification-wrapper .cookie-consent-notification-buttons {
        padding: 0; }
  #cookie-consent-notification.position-top {
    height: auto; }
    @media (min-width: 768px) {
      #cookie-consent-notification.position-top #cookie-consent-notification-wrapper {
        top: 0;
        transform: translate(0, 0); } }

#cookie-consent-tag {
  display: none !important; }

